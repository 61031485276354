<template>
  <svg
    v-if="$theme == 1"
    width="19"
    height="19"
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9569 20.1743L11.576 19.841C2.90932 12.7934 0.575989 10.3172 0.575989 6.26959C0.575989 2.93625 3.29027 0.269592 6.57598 0.269592C9.33789 0.269592 10.9093 1.84101 11.9569 3.03149C13.0046 1.84101 14.576 0.269592 17.3379 0.269592C20.6712 0.269592 23.3379 2.98387 23.3379 6.26959C23.3379 10.3172 21.0046 12.7934 12.3379 19.841L11.9569 20.1743Z"
      :fill="fillColor"
    />
  </svg>
  <svg
    v-else-if="$theme == 2"
    width="19"
    height="19"
    viewBox="0 0 24 21"
    :fill="fillColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9569 20.1743L11.576 19.841C2.90932 12.7934 0.575989 10.3172 0.575989 6.26959C0.575989 2.93625 3.29027 0.269592 6.57598 0.269592C9.33789 0.269592 10.9093 1.84101 11.9569 3.03149C13.0046 1.84101 14.576 0.269592 17.3379 0.269592C20.6712 0.269592 23.3379 2.98387 23.3379 6.26959C23.3379 10.3172 21.0046 12.7934 12.3379 19.841L11.9569 20.1743Z"
      :fill="fillColor"
    />
  </svg>
  <svg
    v-else
    width="19"
    height="19"
    viewBox="0 0 24 21"
    :fill="fillColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9569 20.1743L11.576 19.841C2.90932 12.7934 0.575989 10.3172 0.575989 6.26959C0.575989 2.93625 3.29027 0.269592 6.57598 0.269592C9.33789 0.269592 10.9093 1.84101 11.9569 3.03149C13.0046 1.84101 14.576 0.269592 17.3379 0.269592C20.6712 0.269592 23.3379 2.98387 23.3379 6.26959C23.3379 10.3172 21.0046 12.7934 12.3379 19.841L11.9569 20.1743Z"
      :fill="fillColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    fillColor: {
      type: String,
      default: "#000000",
    },
  },
});
</script>
