<template>
  <div class="flex select-none items-center">
    <button
      class="minus flex h-10 w-10 cursor-pointer items-center justify-center border-[0.6px] border-grey-lightest bg-grey-surface"
      @click="decrement"
    >
      <div :class="{ 'opacity-25': number == 1 }">
        <IconsMinusIcon />
      </div>
    </button>

    <input
      class="h2 number-spinner-input flex h-10 w-10 items-center justify-center rounded-none p-0 text-center text-sm font-bold"
      type="number"
      min="0"
      v-model="number"
      @blur="onInput"
    />

    <button
      class="plus flex h-10 w-10 cursor-pointer items-center justify-center border-[0.6px] border-grey-lightest bg-grey-surface"
      @click="increment"
    >
      <div :class="{ 'opacity-25': max && number + 1 > max }">
        <IconsPlusIcon />
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
const number = defineModel({ type: Number, required: true });

const props = defineProps({
  initial: {
    type: Number,
    default: 1,
  },
  max: Number,
});

const emit = defineEmits<{ (e: "change", value: number): void }>();

const onInput = () => {
  if (props.max && number.value > props.max) {
    number.value = props.initial;
    return;
  }
  emit("change", number.value);
};

const increment = () => {
  if (props.max && number.value + 1 > props.max) {
    return;
  }
  const newNumber = number.value + 1;

  emit("change", newNumber);
};

const decrement = () => {
  if (number.value - 1 < 1) {
    return;
  }
  const newNumber = number.value - 1;

  emit("change", newNumber);
};
</script>
